// LanguageContext.js
import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState('et'); // Vaikimisi keel

    return (
        <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

// Funktsioon, mida saab kasutada teistes komponentides, et ligi pääseda keelele ja seda muuta
export const useLanguage = () => useContext(LanguageContext);
