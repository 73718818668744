import React, { useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Lisage useNavigate
import '../Css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../Components/LanguageContext";


const Footer = () => {
    const navigate = useNavigate(); // Kasutage useNavigate hooki
    const { t } = useTranslation();
    const [showContactInfo, setShowContactInfo] = useState(false);
    const currentYear = new Date().getFullYear();
    const { setCurrentLanguage } = useLanguage();
    const location = useLocation();
    const { currentLanguage } = useLanguage();

    const changeLanguage = (lang) => {
        setCurrentLanguage(lang); // Uuenda keelekonteksti praegust keelt
        
        // Saame praeguse lehe tee
        const currentPath = location.pathname.split('/').slice(2).join('/') || '';
        navigate(`/${lang}/${currentPath}`);
    };

      
/*                 
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
<a href="https://www.facebook.com/profile.php?id=61556321727421" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} size="2x" />
                    </a>
                    <a href="https://www.instagram.com/helikon.medikal/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a> */
    return (
        <footer className="footer">
            <div className="footer-content">
                <Link className="footer-brand" to="/en/copyright">
                <p>© {currentYear} Helikon Medikal OÜ</p>
                </Link>
                <div className="social-media-icons">
                    <a href="https://www.linkedin.com/in/helikon-medikal-01830b2ba/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                    </a>
                </div>
                    <div>
                    <button onClick={() => changeLanguage('et')} className="language-button">
                            <img src="/estonia.webp" alt="Eesti" className="language-icon" />
                        </button>
                        <button onClick={() => changeLanguage('en')} className="language-button">
                            <img src="/united-kingdom.webp" alt="English" className="language-icon" />
                        </button>
                        <button onClick={() => changeLanguage('ru')} className="language-button">
                            <img src="/russias.webp" alt="Русский" className="language-icon" />
                        </button>
                        <button onClick={() => changeLanguage('fin')} className="language-button">
                            <img src="/finland.png" alt="Finland" className="language-icon" />
                        </button>
                    </div>
                <div>
                    <section></section>
                    <div><Link to={`/${currentLanguage}/kontakt`}>{t('jalus.footerContact')}</Link><br/> 
                        {!showContactInfo && (
                            <button className="show-phone-btn" onClick={() => setShowContactInfo(!showContactInfo)}>
                                {t('jalus.phone')}
                            </button>
                        )}
                            {showContactInfo && (
                                <div className="informatsioon">
                                    info@helikon.ee <br/>
                                    tel:+3725151411
                                </div>
                            )}
                            
                    </div>
                    
                </div>
            </div>
        </footer>
    );
};

export default Footer;
