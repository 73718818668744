import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import '../Css/Tooted.css';
import Footer from "../Components/Footer.js";
import { useLanguage } from "../Components/LanguageContext";

function Tooted() {
    const { i18n } = useTranslation();
    const [kategooriad, setKategooriad] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(() => sessionStorage.getItem('selectedCategoryId') || '');
    const [laadimisViga, setLaadimisViga] = useState(null);
    const navigate = useNavigate();
    const { currentLanguage } = useLanguage();

    const loadTootedData = async () => {
        try {
            const loadedKategooriad = await import(`../Data/Tooted${i18n.language.toUpperCase()}.json`);
            setKategooriad(loadedKategooriad.default);

            if (!sessionStorage.getItem('selectedCategoryId') && loadedKategooriad.default.length > 0) {
                const defaultCategoryId = loadedKategooriad.default[0].id.toString();
                setSelectedCategoryId(defaultCategoryId);
                sessionStorage.setItem('selectedCategoryId', defaultCategoryId);
            }
        } catch (error) {
            console.error("Viga toodete andmete laadimisel:", error);
            setLaadimisViga("Tooteid ei õnnestunud laadida. Palun proovige uuesti.");
        }
    };

    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.async = true;
        scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11400263177';
        document.body.appendChild(scriptTag);

        const scriptTag2 = document.createElement('script');
        const scriptContent = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-11400263177');
        `;
        scriptTag2.innerHTML = scriptContent;
        document.body.appendChild(scriptTag2);

        // Adding the Google Ads tag
        const scriptTag3 = document.createElement('script');
        scriptTag3.async = true;
        scriptTag3.src = 'https://www.googletagmanager.com/gtag/js?id=G-TQYRGHFL64';
        document.body.appendChild(scriptTag3);

        const scriptTag4 = document.createElement('script');
        const scriptContent2 = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-TQYRGHFL64');
        `;
        scriptTag4.innerHTML = scriptContent2;
        document.body.appendChild(scriptTag4);

        loadTootedData();
    }, [i18n.language]);

    const handleProductClick = (productId, productName) => {
        const encodedProductName = encodeURIComponent(productName);
        navigate(`/${currentLanguage}/toote-detailid/${productId}/${encodedProductName}`);
    };

    const handleCategoryChange = (categoryId) => {
        setSelectedCategoryId(categoryId);
        sessionStorage.setItem('selectedCategoryId', categoryId);
    };

    const selectedCategory = kategooriad.find(category => category.id.toString() === selectedCategoryId);

    if (!selectedCategory) {
        return (
            <div className="loading-message">
                <div className="animation-container"></div>
                <p>Ootame hetke, tooted on kohe siin</p>
                <p className="witty-message">Kas teadsid? Kõige kiirem asi universumis on valgus, kuid isegi see ei saa meie laadimiskiirusele vastu. Anna meile veel sekund!</p>
                <button onClick={() => window.location.reload()} className="refresh-button">vajutades siia muutub kõik kiiremaks</button>
                <p>Kui ei muutunud kiiremaks</p>
                <Link to={`/${currentLanguage}/kontakt`} className="refresh-button">Kirjuta meile</Link>
            </div>
        );
    }

    return (
        <div className="toodete-leht">
            <div className='katNimi'>
                <h1>{selectedCategory.nimi}</h1>
            </div>
            <div className='kategooriaNupud'>
                {kategooriad.map(category => (
                    <button 
                        key={category.id} 
                        className={`kategooriaNupp ${selectedCategoryId === category.id.toString() ? 'active' : ''}`}
                        onClick={() => handleCategoryChange(category.id.toString())}
                    >
                        {category.nimi}
                    </button>
                ))}
            </div>
            <div className="selectedKategooria">
                <div className="toodeteNimekiri">
                    {selectedCategory.tooted.map(toode => (
                        <div key={toode.id} className="toode" onClick={() => handleProductClick(toode.id , toode.nimi)}>
                            <h3>{toode.nimi}</h3>
                            <img src={`${process.env.PUBLIC_URL}/${toode.pilt}`} alt={toode.nimi} />
                            {toode.hind && <h4>{toode.hind}</h4>}
                            <p>{toode.kirjeldus.length > 150 ? `${toode.kirjeldus.substring(0, 150)}...` : toode.kirjeldus}</p>
                        </div>
                    ))}
                </div>
                {laadimisViga && (
                    <div>
                        <p>{laadimisViga}</p>
                        <button onClick={loadTootedData}>Proovi uuesti</button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Tooted;
