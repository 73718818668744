import React from "react";
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import i18n from './i18n'; // Importige i18next konfiguratsioon
import Avaleht from "./pages/Avaleht";
import Teenused from "./pages/Teenused";
import Kontakt from "./pages/Kontakt";
import Tooted from "./pages/Tooted";
import TooteDetailid from './pages/TooteDetailid';
import Meist from "./pages/Meist";
import CopyRight from "./Components/CopyRight";
import Kasutustingimused from "./Components/Kasutustingimused";
import Privaatsuspoliitika from "./Components/Privaatsuspoliitika";
import Navbar from "./Components/Navbar";
import "./Css/App.css";

const LanguageWrapper = ({ children }) => {
  const { lang } = useParams();

  React.useEffect(() => {
    if (!lang || !['en', 'et', 'ru','fin',].includes(lang)) {
      i18n.changeLanguage('et'); // Kui URLis pole keelt või keel ei ole toetatud, kasutage vaikimisi 'et'
    } else {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return <>{children}</>; // Tagastab lapsed komponendid pärast keele seadmist
};

function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <Routes>
          <Route path="/" element={<LanguageWrapper><Avaleht /></LanguageWrapper>} />
          <Route path="/:lang" element={<LanguageWrapper><Avaleht /></LanguageWrapper>} />
          <Route path="/:lang/tooted" element={<LanguageWrapper><Tooted /></LanguageWrapper>} />
          <Route path="/:lang/toote-detailid/:productId/:productName" element={<LanguageWrapper><TooteDetailid /></LanguageWrapper>} />
          <Route path="/:lang/teenused" element={<LanguageWrapper><Teenused /></LanguageWrapper>} />
          <Route path="/:lang/meist" element={<LanguageWrapper><Meist /></LanguageWrapper>} />
          <Route path="/:lang/kontakt" element={<LanguageWrapper><Kontakt /></LanguageWrapper>} />
          <Route path="/:lang/copyright" element={<LanguageWrapper><CopyRight /></LanguageWrapper>} />
          <Route path="/:lang/kasutustingimused" element={<LanguageWrapper><Kasutustingimused /></LanguageWrapper>} />
          <Route path="/:lang/privaatsuspoliitika" element={<LanguageWrapper><Privaatsuspoliitika /></LanguageWrapper>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
