// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // for Firestore
import { getAuth } from 'firebase/auth'; // for Authentication
import { getStorage } from 'firebase/storage'; // for Storage
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCnD95r_sbj0ASvIlSadBC9C48ya9yYtLc",
    authDomain: "leht-78dd7.firebaseapp.com",
    projectId: "leht-78dd7",
    storageBucket: "leht-78dd7.appspot.com",
    messagingSenderId: "999386612993",
    appId: "1:999386612993:web:1da8de0f0166f1d6080700",
    measurementId: "G-7B81SY12W8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Export Firebase services for use in your components
export { auth, db, storage, analytics };
