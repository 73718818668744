import React, { useState } from "react";
import { NavLink} from "react-router-dom";
import "../Css/Navbar.css";
import logo from "../Pictures/HelikonLogo4.webp"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../Components/LanguageContext";

const Navbar = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    // Kui lang parameetrit ei ole (nt, kasutaja on juur-URL-is), määrame vaikimisi keele
    const { currentLanguage } = useLanguage();
    return (
        <nav className="navbar">
            <NavLink to={`/${currentLanguage}`} className="nav-menu-item logo">
                <img src={logo} alt="Home" className="logo-image" />
            </NavLink>
            <button className="menu-toggle" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={faBars} size="2x" />
            </button>
            <ul className={`nav-menu-list ${isOpen ? "open" : ""}`}>
                <li className="nav-menu-item">
                    <NavLink to={`/${currentLanguage}/tooted`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.products')}</NavLink>
                </li>
                <li className="nav-menu-item">
                    <NavLink to={`/${currentLanguage}/teenused`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.services')}</NavLink>
                </li>
                <li className="nav-menu-item">
                    <NavLink to={`/${currentLanguage}/meist`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.aboutUs')}</NavLink>
                </li>
                <li className="nav-menu-item">
                    <NavLink to={`/${currentLanguage}/kontakt`} className={({ isActive }) => (isActive ? "active" : "inactive")}>{t('navbar.contact')}</NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
