// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import translationEN from './Pictures/en.json';
import translationET from './Pictures/et.json';
import translationRU from './Pictures/ru.json';
import translationFIN from './Pictures/fin.json';

// Function to get the user's language preference from URL parameters
const getUrlLanguage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('lang') || 'et'; // Default to Estonian if not found
};

// Initialize i18next with plugins and options
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      et: { translation: translationET },
      ru: { translation: translationRU },
      fin: { translation: translationFIN },
    },
    lng: getUrlLanguage(), // Set initial language to the user's preference from URL
    fallbackLng: 'et',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

  export const changeLanguage = (language) => {
    i18n.changeLanguage(language).then(() => {
      console.log(`Language changed to ${language}.`);
      // URL-i muutmine peaks toimuma React Routeri kaudu kasutades useNavigate hooki komponentides.
    }).catch(error => {
      console.error('Failed to change language:', error);
    });
  };
  

export default i18n;
