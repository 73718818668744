import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../Css/TooteDetailid.css';
import Footer from '../Components/Footer';
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../Components/LanguageContext";
import Modal from 'react-modal';

const extractYouTubeID = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
};

const getYouTubeEmbedUrl = (url) => {
    const videoId = extractYouTubeID(url);
    return `https://www.youtube.com/embed/${videoId}?rel=0`;
};

const TooteDetailid = () => {
    const { t, i18n } = useTranslation();
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [mainImageIsVideo, setMainImageIsVideo] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { currentLanguage } = useLanguage();

    useEffect(() => {
        import(`../Data/Tooted${i18n.language.toUpperCase()}.json`)
            .then(tooted => {
                const allProducts = tooted.default.flatMap(category => category.tooted);
                const foundProduct = allProducts.find(toode => toode.id.toString() === productId);
                if (foundProduct) {
                    setProduct(foundProduct);
                    if (foundProduct.videoUrl) {
                        setMainImage(getYouTubeEmbedUrl(foundProduct.videoUrl));
                        setMainImageIsVideo(true);
                    } else {
                        setMainImage(foundProduct.pilt || '');
                        setMainImageIsVideo(false);
                    }
                }
            });
    }, [productId, i18n.language, currentLanguage]);

    const handleThumbnailClick = (imageUrl, isVideo = false) => {
        setMainImage(isVideo ? getYouTubeEmbedUrl(imageUrl) : imageUrl);
        setMainImageIsVideo(isVideo);
    };

    const toggleZoom = () => setIsZoomed(!isZoomed);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    if (!product) {
        return (
            <div className="loading-message flex flex-col items-center justify-center min-h-screen">
                <div className="animation-container spinner"></div>
                <p>Ootame hetke, toode on siin...</p>
                <button onClick={() => window.location.reload()} className="refresh-button">Vajutades siia muutub kõik kiiremaks</button>
                <Link to={`/${currentLanguage}/kontakt`} className="refresh-button">Kirjuta meile</Link>
            </div>
        );
    }

    return (
        <>         
            <div className="container mx-auto py-12">
                <div className="bg-white p-6 rounded-lg shadow-md tooteInfo">
                    <h2 className="text-2xl font-bold mb-4">{product.nimi}</h2>
                    <Link to={`/${currentLanguage}/tooted`} className="button button-primary">{t('productDetails.tagasi')}</Link>
                    <div className="imageGallery flex justify-center gap-4 mt-4">
                        {mainImageIsVideo ? (
                            <div className="video-responsive">
                                <iframe
                                    src={mainImage}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ) : (
                            <img
                                src={`${process.env.PUBLIC_URL}/${mainImage}`}
                                alt={product.nimi}
                                className={`mainImage ${isZoomed ? 'zoomedIn' : ''}`}
                                onClick={openModal}
                            />
                        )}
                        <div className="thumbnailContainer flex flex-col gap-2 overflow-y-auto max-h-64">
                            {product.videoUrl && (
                                <img src={`${process.env.PUBLIC_URL}/TootePildid/videothumb.webp`}
                                    alt={`${product.nimi} video`}
                                    className="thumbnail" 
                                    onClick={() => handleThumbnailClick(product.videoUrl, true)}
                                />
                            )}
                            {[product.pilt, ...(product.pildid || [])].map((imgUrl, index) => (
                                <img key={index} src={`${process.env.PUBLIC_URL}/${imgUrl}`} 
                                    alt={`${product.nimi} ${index + 1}`} 
                                    className="thumbnail" 
                                    onClick={() => handleThumbnailClick(imgUrl, false)}
                                />
                            ))}
                        </div>
                    </div>
                    <section className='kirjeldus mt-6'>
                        <h3 className="text-xl font-semibold">{t('productDetails.overview')}</h3>
                        <p className="mt-2">{product.kirjeldus}</p>
                        <ul className="list-disc list-inside mt-2">
                            {product.omadused.map((omadus, index) => (
                                <li key={index}>{omadus}</li>
                            ))}
                        </ul>
                        <p className="mt-2">{product.kirjeldus2}</p>

                        {product.datasheets && product.datasheets.length > 0 && (
                            <div className="datasheet-section mt-4">
                                <h3 className="text-xl font-semibold">Andmelehed</h3>
                                <ul>
                                    {product.datasheets.map((datasheet, index) => (
                                        <li key={index}>
                                            <a href={`${process.env.PUBLIC_URL}/${datasheet.url}`} target="_blank" rel="noopener noreferrer">
                                                {datasheet.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                    </section>
                    <Link to={`/${currentLanguage}/kontakt`} className="button">{t('productDetails.button')}</Link>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Image Gallery"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <img
                    src={`${process.env.PUBLIC_URL}/${mainImage}`}
                    alt={product.nimi}
                    className="modal-image"
                />
                <button onClick={closeModal} className="close-modal">Sulge</button>
            </Modal>

            <Footer />
        </>
    );
};

export default TooteDetailid;
