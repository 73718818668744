import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';
import "../Css/Kontakt.css";
import Footer from "../Components/Footer";
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t } = useTranslation();
    const form = useRef();
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [emailSent, setEmailSent] = useState(false); // Olekumuutuja e-kirja saatmise teavituse jaoks
    const [isSending, setIsSending] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true);

        emailjs.sendForm('service_c69rahs', 'template1', form.current, 'ShHy94YxOZ0ZdWgst')
            .then((result) => {
                console.log(result.text);
                setEmailSent(true); // Näitame teavitust
                resetForm(); // Lähtestame vormi väljad
                setTimeout(() => setEmailSent(false), 5000); // Peidame teavituse pärast 5 sekundit
            }, (error) => {
                console.log(error.text);
                // Käsitse veateadet siin
            }).finally(() => {
                setIsSending(false);
            });
    };

    const resetForm = () => {
        setName('');
        setEmail('');
        setMessage('');
        setEmailError('');
        setIsFormValid(false);
    };

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        validateForm();
    };

    const handleEmailValidation = (e) => {
        const isValid = validateEmail(e.target.value);
        setEmail(e.target.value);
        setEmailError(isValid ? '' : t('contactForm.invalidEmailError'));
        validateForm();
    };

    const validateForm = () => {
        const isValid = name !== '' && validateEmail(email) && message !== '';
        setIsFormValid(isValid);
    };

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return (
        <>
            <div className="kontakt-wrapper">
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <div className="form-group">
                    <label htmlFor="from_name" className="form-label">{t('contactForm.nameLabel')}</label>
                    <input type="text" id="from_name" name="from_name" value={name} onChange={handleInputChange(setName)} placeholder={t('contactForm.namePlaceholder')} className="form-input" required />
                </div>
                <div className="form-group">
                    <label htmlFor="reply_to" className="form-label">{t('contactForm.emailLabel')}</label>
                    <input type="email" id="reply_to" name="reply_to" value={email} onChange={handleEmailValidation} onBlur={handleEmailValidation} placeholder={t('contactForm.emailPlaceholder')} className="form-input" required />
                    {emailError && <div className="form-error">{emailError}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="message" className="form-label">{t('contactForm.messageLabel')}</label>
                    <textarea id="message" name="message" value={message} onChange={handleInputChange(setMessage)} placeholder={t('contactForm.messagePlaceholder')} className="form-textarea" required />
                </div>
                {isFormValid && (
                    <button type="submit" className="form-submit" disabled={isSending}>
                        {isSending ? <span className="sending-text">{t('contactForm.sendingButton')}</span> : t('contactForm.sendButton')}
                    </button>
                )}
            </form>
            {emailSent && <div className="email-sent-confirmation">{t('contactForm.emailSent')}</div>}
            <button className="toggle-contact-info" onClick={() => setShowContactInfo(!showContactInfo)}>
                {showContactInfo ? t('contactForm.hideContactInfo') : t('contactForm.showContactInfo')}
            </button>
            {showContactInfo && (
                <div className="contact-info">
                    <h2>{t('contactForm.contactInfo')}</h2>
                    <p><strong>{t('contactForm.addressTitle')}:</strong> <a href="https://www.google.com/maps/search/?api=1&query=Estonia,+Tallinn,+Tartu+mnt+74,+110113" target="_blank" rel="noopener noreferrer">{t('contactForm.address')}</a></p>
                    <p><strong>{t('contactForm.phoneTitle')}: </strong> <a href="tel:+37212345678" className="contact-link">+372 5151411</a></p>
                    <p><strong>{t('contactForm.emailTitle')}: </strong> <a href="mailto:info@helikon.ee" className="contact-link">info@helikon.ee</a></p>
                </div>
            )}
                </div>
            <Footer />
        </>
    );
    
}

export default ContactForm;
