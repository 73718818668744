import React, { useEffect } from "react";

import useSlideInAnimation from "../Components/SlideInAnimation";
import '../Css/Avaleht.css';
import Footer from "../Components/Footer";
import { useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; // Import slick slider styles
import "slick-carousel/slick/slick-theme.css"; // Slider theme (optional)
import LogosLoop from '../Components/kaheksa';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useTranslation } from 'react-i18next';
import { useLanguage } from "../Components/LanguageContext";




function Avaleht() {
  const { t } = useTranslation();
  const [aboutRef, isAboutVisible] = useSlideInAnimation();
  const { currentLanguage } = useLanguage();
  const navigate = useNavigate();
  
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname
    });
  }, []);

  
  const products = [
    { id: 1, name: t("products.iMac12EKG"), image: "Logod/webp/zoncare7.webp", productId: 80 },
    { id: 2, name: t("products.LiveTecEKGHolter"), image: "Logod/webp/inimesega-kecg2.webp", productId: 70 },
    { id: 3, name: t("products.DHeartEKG"), image: "Logod/webp/Dheart12.webp", productId: 30 },
    { id: 4, name: t("products.SpirobankII"), image: "Logod/webp/spiro2.webp", productId: 90 },
    { id: 5, name: t("products.AktiiaBloodPressureHolter"), image: "Logod/webp/bp12.webp", productId: 100 },
  ];

  const handleProductClick = (productId, productName) => {
    const encodedProductName = encodeURIComponent(productName);
    navigate(`/${currentLanguage}/toote-detailid/${productId}/${encodedProductName}`);
  };
  
  return (

    
    <>
      <div className="avaleht">
      <div className="products-container">
          {products.map((product) => (
            <div key={product.id} className="product" onClick={() => handleProductClick(product.productId, product.name)}>
              <img src={product.image} alt={product.name} className="product-image" />
              <h3 className="product-name">{product.name}</h3>
            </div>
        ))}


        </div>
        <section ref={aboutRef} className={`about ${isAboutVisible ? 'slide-in-right' : ''}`}>
          <h1>
            <span class="main-text">Non Vi Sed Arte</span>
            <span class="translation"> -  "{t('avaLeht.kirjeldus4')}"</span>
          </h1>
          <h2>
          {t('avaLeht.kirjeldus5')}<br/>
          {t('avaLeht.kirjeldus6')}<br/>
          {t('avaLeht.kirjeldus7')}<br/>
          </h2>
        </section>
        <div className="logos-container">
        <LogosLoop />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Avaleht;
