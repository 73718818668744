// hooks/useSlideInAnimation.js
import { useEffect, useRef, useState } from 'react';

const useSlideInAnimation = (direction = 'left') => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) setIsVisible(true);
        });
      },
      {
        threshold: 0.5,
        rootMargin: "0px"
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      observer.disconnect();
    };
  }, []);

  return [ref, `slide-in-${direction} ${isVisible ? 'visible' : ''}`];
};

export default useSlideInAnimation;
